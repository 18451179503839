import { Row, Col, Button, Container } from "react-bootstrap"
import style from "./style.module.css"
import React, { useEffect } from "react"
import {sendEmailToClaimant,sendEmailToRP,createCreditorSaveAsDraft} from "../../APIFolder/api"


const AfterSubmission = props => {
  const { nextScreen, creditorDetails } = props.methods
  useEffect(()=>{
    const obj={creditorDetails}
    sendEmailToClaimant(obj)
    sendEmailToRP(obj)

    var dt = new Date();
    var dtstr = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
    document.getElementById("col_lbl").innerHTML = "Your claim number is <b>PCL_CA_"+ creditorDetails.creditor.c_obj.claim_no_abcd + "</b> filed on "+dtstr+".Please save the claim number for future reference.";
    creditorDetails.creditor.c_obj["form_uploaded"] = "yes"
    creditorDetails.creditor.c_obj["edit_done"] = "yes"
    createCreditorSaveAsDraft(creditorDetails.creditor.c_obj);
},[])
  return (
    <Container fluid className={style.mainBlock}>
      <Row className={style.firstRow}>
        <Col>FORM SUBMITTED SUCCESSFULLY!</Col>
      </Row>
      <Row className={style.secondRow}>
        {/*<Col>A COPY OF THE FORM HAS BEEN SENT TO YOUR REGISTERED EMAIL ID.</Col>*/}
        <Col><span id={"col_lbl"}>&nbsp;</span></Col>
      </Row>
    </Container>
  )
}

export default AfterSubmission
