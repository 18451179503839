import style from "./style.module.css"
import logo from "../Assets/main_logo.png"
import {
  Row,
  Container,
  DropdownButton,
  Dropdown,
  Button,
  Collapse,
} from "react-bootstrap"
import notification from "../Assets/notification.png"
import face from "../Assets/face.png"
import group from "../Assets/group.png"
import { useHistory, Link } from "react-router-dom"
import auth from "../Authentication/Auth"
import Hamburger from "hamburger-react"
import { useState } from "react"
import {
  Sidebar,
  InputItem,
  DropdownItem,
  Item,
  Toggler,
} from "react-sidebar-ui"
import {exportLogs,getSessionToken} from "../APIFolder/api"
import domainMapping from "../Utlitiy/domainMapping"

const Header = props => {
  const { login } = props
  const history = useHistory()
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  
  const redirectToDrive=(token)=>{ window.open(`${domainMapping[window.location.protocol+"//" + window.location.host]}/appnew/drive.html?t=${token}`, "_blank")}
const openDrive= ()=>{
getSessionToken(redirectToDrive)
}
const items = [
    {
    name:"DRIVE",
    click:openDrive
  },
  {
    name:"EXPORT",
    click:()=>{
      //  this api will fetch details from database and will create a excel sheet 
      exportLogs() }
  },
  
  {
    name: "RP PORTAL",
    click:()=>{}}
]
const logout= ()=>{
  auth.logout()
  history.push("/")
}
const downloadIBBIForms="https://ibbi.gov.in/home/downloads"

function openvideourl(type, event){
  if(type==1){
    window.open("https://www.youtube.com/watch?v=UF7a4Y3KKbM", "_blank");
  } else if(type==2){
    window.open("https://www.youtube.com/watch?v=OuLXP159Wio", "_blank");
  } else if(type==3){
    window.open("https://youtu.be/A10SAwTuoaM", "_blank");
  } else if(type==4){
    window.open("https://resolute.dcirrus.io/PCL_Edit_Claim_Guide.pdf", "_blank");
  } else if(type==5){
    window.open("https://resolute.dcirrus.io/Data_Field_Clarification.pdf", "_blank");
  } 
}

  return (
    <Container fluid>
      <Row className={style.navbar}>
        <a href='/'>
          <img alt='Resolute' className={style.logo} src={logo} />
        </a>
        {/*  for smaller screens */}
        <div className={style.hamburgerMenu}>
          <Hamburger toggled={isSidebarOpen} toggle={setSidebarOpen} />
          {login ? (
            <Sidebar bgColor='black' isCollapsed={!isSidebarOpen}
              classes={isSidebarOpen ? style.sidebarOpen : style.sidebarClose}>
              {items.map((obj, index) => {
                return (
                  <Item bgColor='black' onClick={obj.click}>
                    <Button variant='secondary' block>
                        {obj.name}
                      </Button>
                  </Item>
                )
              })}

              <Item
                bgColor='black'
                onClick={() => {
                  auth.logout()
                  history.push("/")
                }}
              >
                <img src={group} className='mr-3' alt='Log Out' />
                Log out
              </Item>
            </Sidebar>
          ) : (
            <Sidebar bgColor='black' isCollapsed={!isSidebarOpen}
              classes={isSidebarOpen ? style.sidebarOpen : style.sidebarClose}>
              <Item bgColor='black'>
                <Button variant='info'
                  onClick={() => {
                    window.open(downloadIBBIForms, "_blank")
                  }}
                  block>
                  DOWNLOAD IBBI FORMS
                </Button>
              </Item>
            </Sidebar>
          )}
        </div>
        {/*<Button className="mr-1 px-3" onClick={() => openvideourl(1)}>क्लेम कैसे दर्ज करें</Button>
        <Button className="mr-1 px-3" onClick={() => openvideourl(2)}>Claim filing quick guide</Button>
        <Button className="mr-1 px-3" onClick={() => openvideourl(4)} style={{background:"green"}}>PCL Edit Claim Guide</Button>
        <Button className="mr-1 px-3" onClick={() => openvideourl(5)} style={{background:"green"}}>Data Field Clarification</Button>
        */}
        {/* <Button className="mr-1 px-5" style={{background:"red"}} onClick={() => openvideourl(3)}>FAQ</Button> */}
        {/*  for larger screens */}
        {login ? (
          <div className={style.rightSideBar}>
            <Button size="lg" onClick={openDrive}>DRIVE</Button>
            <Button size="lg" disabled={!auth.isRootFolderSelected()} onClick={exportLogs}>
             EXPORT 
            </Button>
            <Link className={` ${style.rpPortal}`}>
              <img src={face} alt='' />
              <DropdownButton
                id='dropdown-basic-button'
                className={style.dropdownMenu}
                title='RP PORTAL'
              >
                <Dropdown.Item
                  href='#'
                  onClick={() => {
                    auth.logout()
                    history.push("/")
                  }}
                >
                  <p className='d-flex m-0'>
                    <img src={group} className='mr-3' alt='Log Out' />
                    Log out
                  </p>
                </Dropdown.Item>
              </DropdownButton>
            </Link>
          </div>
        ) : (
          
          /*<a
            href={downloadIBBIForms}
            target='_blank'
            className={style.downloadForm}
          >
            DOWNLOAD IBBI FORMS
          </a>*/
          <div> </div>
        )}
      </Row>
    </Container>
  )
}

export default Header
