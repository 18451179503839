import urls from "./api_urls"
import axios from "axios"
import auth from "../Authentication/Auth"
import notification from "../Utlitiy/notification"
import encryption from "../Utlitiy/encryption"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

axios.defaults.withCredentials = true;
const key = '?E(H+MbQeThWmZq4t7w!z$C&F)J@NcRfUjXn2r5u8x/A?D*G-KaPdSgVkYp3s6v9y$B&E)H@MbQeThWmZq4t7w!z%C*F-JaNdRfUjXn2r5u8x/A?D(G+KbPeShVkYp3s6v9y$B&E)H@McQfTjWnZq4t7w!z%C*F-JaNdRgUkXp2s5u8x/A?D(G+KbPeShVmYq3t6w9y$B&E)H@McQfTjWnZr4u7x!A%C*F-JaNdRgUkXp2s5v8y/B?E(G+KbPeSh'

//  if there is any problem with the api then just trigger this method
const logout = () => {
  auth.logout()
  window.location.reload("/")
}

//  if token expires trigger this notification
const technicalErrorNotification = (data) => {
  if (data && data.error === true) {
    notification.someProblem()
    setTimeout(() => logout(), 1000)
  }
}
//  ist api
export const getCaptcha = (setCaptcha, fileUploadingObject) => {
  fileUploadingObject.fileUploadingStart()
  axios
    .post(urls.captcha)
    .then(res => {
      fileUploadingObject.fileUploadingStop()
      const { data } = res
      //  201 code considered success
      if (data && data.messageCode === 201) {
        //   console.log(data.objectD)
        setCaptcha(data.objectD)
      }
    })
    .catch(err => {
      fileUploadingObject.fileUploadingStop()
      console.error("error", err)
    })
}

//  2nd api
export const login = (obj) => {
  const { body, loginMethod, redirectToDashboard } = obj
  axios
    .post(urls.login, body)
    .then(res => {
      const { data } = res
      //  console.log("login response", res)
      //  201 code considered success
      if (data && data.messageCode === 201) {
        const { token, emailId, userId, name } = data.objectD
        //  store the token in localstorage
        const obj = { token, rp_email: emailId, rp_id: userId, rp_name: name }
        loginMethod(obj)
        // redirect user to dashboard screen
        redirectToDashboard()
      }
      else if (data && data.messageCode === 424) {
        notification.wrongCaptcha()
      }
      else if (data && data.messageCode === 425) {
        notification.wrongCredentials()
      }
      else if (data && data.messageCode === 435) {
        notification.accoundBlocked()
      }
      else if (data && data.messageCode === 422) {
        notification.accountExpired()
      }


    })
    .catch(err => {
      console.error("error", err)
    })
}

//  3rd api
export const getFolders = setFolders => {
  axios
    .get(urls.getFolders, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
    })
    .then(res => {
      const { data } = res
      //   console.log("get Folders API response", res)
      technicalErrorNotification(data)
      //  200 code considered success
      if (data && data.messageCode === 200) {
        const localArray = []
        data.object.map(obj => {
          const { folderId, folderNM, fileCreatedDate, userId } = obj
          localArray.push({ folderId, folderNM, fileCreatedDate, userId })
        })
        setFolders(localArray)
      }
    })
    .catch(err => {
      console.error("error", err)
    })
}

export const generateTokenAPI = obj => {
  const { previousScreen, decryptedObject, setDecryptedObject, fileUploadingObject, startAllowing } = obj
  fileUploadingObject.fileUploadingStart()
  axios
    .post(urls.generateToken, {}, {
      headers: {
        Authorization: "Bearer " + decryptedObject.token,
        a: key
      },
    })
    .then(res => {
      fileUploadingObject.fileUploadingStop()
      const data = res.data.res
      //console.log("generate token api Response", res.data, data)
      if (data && data.messageCode === 201) {
        const { object } = data
        decryptedObject.token = object
        setDecryptedObject(decryptedObject)
        //  start executing other apis
        startAllowing()
      }

      //to be removed
      startAllowing()
    })
    .catch(err => {
      fileUploadingObject.fileUploadingStop()
      console.error("error", err)
      notification.tryAgain()
      previousScreen()
    })
}

//  4th api
export const createRootFolder = (folderName, setFolders) => {
  const structure = {
    folderPath: "",
    status: "A",
    folderType: "P",
    parentFolderId: 0,
  }

  const rootFolderStructure = { ...structure }
  rootFolderStructure.folderPath = folderName
  axios
    .post(urls.createFolder, rootFolderStructure, {
      headers: {
        Authorization: "Bearer " + auth.getToken()
      },
    })
    .then(res => {
      const { data } = res
      // console.log("create Root Folder Response", res)
      technicalErrorNotification(data)
      if (data && data.messageCode === 201) {
        //  if root folder already exist then this mesage
        if (data.message === "FOLDEREXISTS") {
          notification.folderAlreadyCreated(folderName)
        } else {
          notification.folderCreated(folderName)

          const folderId = data.object.split("#")[0]
          const folderNM = folderName
          const userId = auth.getRPId()
          const localArray = [{ folderNM, folderId, userId }]
          setFolders(arr => [...arr, ...localArray])

          const subFolders = [
            `${folderNM}/Operational`,
            `${folderNM}/Financial`,
          ]

          subFolders.map(eachFolderPath => {
            var subFolderStructure = { ...structure }
            subFolderStructure.folderPath = eachFolderPath
            subFolderStructure.parentFolderId = folderId
            createSubFolders(subFolderStructure)
          })
        }
      }
    })
    .catch(err => {
      console.error("error", err)
      notification.folderNotCreated(folderName)
    })
}
//  5th api
export const createSubFolders = folderStructure => {
  axios
    .post(urls.createFolder, folderStructure, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
    })
    .then(res => {
      const { data } = res
      // console.log("create sub Folder Response", res)

      //  201 code considered success
      if (data && data.messageCode === 201) {
        var localArr = folderStructure.folderPath.split("/")

        if (data.message === "FOLDEREXISTS") {
          notification.folderAlreadyCreated(localArr[localArr.length - 1])
        } else {
          notification.folderCreated(localArr[localArr.length - 1])
        }
      }
    })
    .catch(err => {
      console.error("error", err)
      notification.folderNotCreated(folderStructure.folderPath.split("/")[1])
    })
}
//  6th api
export const getSubFolders = (obj, setSpecialUrl) => {
  axios
    .get(
      `${urls.baseURL}app/unindexdoclist/0/0/${obj.folderId}/DESC%60lastmodified/fetchAllAdmFolderChildListResponse`,
      {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      }
    )
    .then(res => {
      const { data } = res
      // console.log("get subfolders api Response", res)
      technicalErrorNotification(data)
      if (data && data.messageCode === 200) {
        const subFoldersName = ["Operational", "Financial"]
        const subfolders = data.object.unIndexFoldersList.filter(rec => subFoldersName.includes(rec.folderPathLastChild))

        //    console.log("subfolders response", subfolders)
        if (subfolders.length === 0) {
          notification.emptySubFolders()
        }
        else {


          var o_obj, f_obj
          subfolders.map(folders => {
            if (folders.folderPathLastChild === "Operational")
              o_obj = { id: folders.folderId, folderPath: folders.folderPath }
            else if (folders.folderPathLastChild === "Financial")
              f_obj = { id: folders.folderId, folderPath: folders.folderPath }
          })

          const encryptedUrl = {
            token: auth.getToken(),
            rp_id: auth.getRPId(),
            rp_name: auth.getRPName(),
            rp_email: auth.getRPEmail(),
            operational: o_obj,
            financial: f_obj,
            rootFolderId: obj.folderId
          }
          auth.setRootFolderId(obj.folderId)
          //    console.log("before encryption", encryptedUrl)
          //  Encrypt
          //var HOST_URL=(window.location.href).replace("/dashboard","")
          const token = encryption.encrypt(encryptedUrl)

          //const creditorUrl = HOST_URL+`/creditor?e=${token}`
          const creditorUrl = `https://${document.location.host}/creditor?e=${token}`
          //        console.log("https://creditorUrl------------------->", creditorUrl)
          //  we are using the tinyurl service to short our large url
          urlShortener(creditorUrl, setSpecialUrl)
        }
      }
    })
    .catch(err => {
      console.error("get subfolders api error", err)
    })
}
export const urlShortener = (creditorUrl, setSpecialUrl) => {

  // axios.get(`${urls.urlShortener}?url=${encodeURIComponent(creditorUrl)}`
  // )
  const body = { url: creditorUrl }
  axios.post(urls.urlShortener, body)
    .then(response => {
      if (response.status === 200) {
        //    console.log("response of url shortener api ", response)
        const url = response.data.url
        setSpecialUrl(url)
        notification.urlGenerated()
      }
    }).catch(err => {
      console.log("error ", err)
      notification.urlNotGenerated()
    }
    )
}

//  7th api
export const createCreditorFolder = obj => {
  const { creditorDetails, decryptedObject, showModal, fileUploadingObject } = obj
  const { creditor, updateCreditorDetails } = creditorDetails

  const updateCreditorFolderId = creditorFolderId => {
    creditor.c_obj.creditorFolderId = creditorFolderId
    updateCreditorDetails(creditor)
  }

  const { c_obj } = creditor
  const folderObject = decryptedObject[c_obj.creditor_claim]

  var rootfdpth = c_obj.rootfolderobj.financial.folderPath
  rootfdpth = rootfdpth.split("/")[0];
  
  const creditorFolderStructure = {
    folderPath: rootfdpth + "/" + c_obj.creditor_claim + "/" + c_obj.claim_no_abcd,
    status: "A",
    folderType: "P",
    parentFolderId: c_obj.rootFolderId
  }
  fileUploadingObject.fileUploadingStart()
  axios
    .post(urls.createCreditorFolder, { creditorFolderStructure: creditorFolderStructure }, {
      headers: {
        Authorization: "Bearer " + decryptedObject.token,
        a: key
      },
    })
    .then(res => {
      fileUploadingObject.fileUploadingStop()
      const data = res.data.res
      console.log("create Creditor Folder Response", data)
      if (data && data.messageCode===201 && data.error===false && (data.object+"").indexOf("#")>0) {
        const creditorFolderId = data.object.split("#")[0]
        //console.log("creditorFolderId----->" + creditorFolderId)
        updateCreditorFolderId(creditorFolderId)
        const creditorFolderExist = data.message === "FOLDEREXISTS"
        showModal(creditorFolderExist)
      } else if (data && data.messageCode === 500) {
        notification.sessionExpired()
      }
    })
    .catch(err => {
      fileUploadingObject.fileUploadingStop()
      console.error("error", err)
      notification.tryAgain()
    })
}
export const onConfirmation = obj => {
  const {
    creditorDetails,
    decryptedObject,
    nextScreen,
    focusCreditorField,
    confirmationStatus,
    fileUploadingObject,
  } = obj
  const { creditor, updateCreditorDetails } = creditorDetails
  const { c_obj, f_obj } = creditor
  const creditorFolderId = c_obj.creditorFolderId


  const removeCreditorId = () => {
    creditor.c_id = ""
    updateCreditorDetails(creditor)
  }
  if (confirmationStatus === "true") {

    var fileUploadArray = []
    f_obj.files.map(files => {
      fileUploadArray.push({
        attribute1: creditorFolderId,
        attribute2: files.fileName,
        attribute3: files.fileSize,
      })
    })
    const fileUploadStructure = {
      listAttribute5: fileUploadArray,
    }
    const obj_1 = {
      decryptedObject,
      fileUploadStructure,
      nextScreen,
      creditorDetails,
      fileUploadingObject,
    }
    // used to start and stop loading indicator as our file uploading work started

    // fileUploadingObject.fileUploadingStart()
    fileUpload(obj_1)
  } else {
    removeCreditorId()
    setTimeout(() => focusCreditorField(), 500)
  }
}
//  8th api
export const fileUpload = obj => {
  const { decryptedObject, fileUploadStructure, nextScreen, creditorDetails, fileUploadingObject } =
    obj
  fileUploadingObject.fileUploadingStart()
  axios
    .post(urls.fileUpload, { fileUploadStructure: fileUploadStructure }, {
      headers: {
        Authorization: "Bearer " + decryptedObject.token,
        a: key
      },
    })
    .then(res => {
      const data = res.data.res
      console.log("file upload Response", data)
      if (data && data.messageCode === 202 && data.error===false) {
        var results = []
        if (data.object !== null) {
          results = [...data.object]
        }
        //  if files are not uploaded then server return 0 for new file in attribute4
        // so we are taking only new files and the files which were already uploaded have the same url
        //  so no need to call update meta data api
        const fileDetails = [...fileUploadStructure.listAttribute5]
        //  we have to shift this code when our file uploading api started working properly
        getCreditorDetails(creditorDetails, fileUploadingObject)

        const obj_1 = {
          results,
          decryptedObject,
          fileDetails,
          nextScreen,
          creditorDetails,
          fileUploadingObject
        }
        uploadToAws(obj_1)
      }
    })
    .catch(err => {
      fileUploadingObject.fileUploadingStop()
      console.error("error", err)
      notification.tryAgain()
    })
}
//  9th api
export const getCreditorDetails = (creditorDetails, fileUploadingObject) => {
  const { creditor, updateCreditorDetails } = creditorDetails
  const obj = creditor.c_obj
  var query = `creditor=${obj.creditor}&`
  query += `resolution_professional=${obj.resolution_professional}&`
  query += `creditor_claim=${obj.creditor_claim}&`
  query += `email_id=${obj.email_id}&`
  query += `phone_number=${obj.phone_number}`

  const updateCreditorId = (creditor, creditorId) => {
    creditor.c_id = creditorId
    updateCreditorDetails(creditor)
  }
  fileUploadingObject.fileUploadingStart()
  axios
    .get(urls.getCreditorDetails + "?" + query)
    .then(res => {
      fileUploadingObject.fileUploadingStop()
      //   console.log("get CreditorDetails Api response", res)

      if (res.data.length === 0) {
        createCreditorDetails(creditorDetails)
      } else {
        const creditorId = res.data._id
        updateCreditorId(creditor, creditorId)
      }
    })
    .catch(err => {
      console.log("err", err)
      fileUploadingObject.fileUploadingStop()
      notification.tryAgain()
    })
}

//  10th api
export const createCreditorDetails = (creditorDetails) => {
  const { creditor, updateCreditorDetails } = creditorDetails
  var obj = { ...creditor.c_obj }

  const updateCreditorId = (creditor, creditorId) => {
    creditor.c_id = creditorId
    updateCreditorDetails(creditor)
  }
  //  we are removing this field because we dont require
  //  delete obj["creditor_claim"]
  // console.log("creditor", obj)
  axios
    //.post(urls.creditorDetails, obj)
    .post(urls.updateCreditorDetails, obj)
    .then(res => {
      //   console.log("create Creditor  update Api response", res)
      const creditorId = res.data._id
      updateCreditorId(creditor, creditorId)
    })
    .catch(err => console.log("err", err))
}

//  11th api
const uploadToAws = obj => {
  const { results, decryptedObject, fileDetails, nextScreen, creditorDetails, fileUploadingObject } =
    obj
  const { creditor, updateCreditorDetails } = creditorDetails
  const rp_id = creditor.c_obj.rp_id
  const files = creditor.f_obj.files
  var feedbackArray = []

  results.map((eachUrl, index) => {
    axios
      .put(results[index].attribute3.replace("http://", "https://"),
        files[index].eachFile,
      )
      .then(res => {
        //  update metaData
        //     console.log("aws response ok", res)
        const metaDataObject = {
          userId: rp_id,
          folderId: results[index].attribute1,
          parentFolderId: results[index].attribute1,
          storageFileName: results[index].attribute2,
          fileName: results[index].attribute5,
          fileSize: fileDetails[index].attribute3,
          fileType: results[index].attribute2.split(".")[1],
          status: "A",
          deleteStatus: "",
          folderType: "P",
          fileUniqueId: "",
          fileId: results[index].attribute4,
        }
        feedbackArray.push(metaDataObject)
        if (results.length === feedbackArray.length) {
          //  an array of results from aws
          //  it means files are uploaded  or not
          //  if yes then inserted an object otherwise returns false
          const obj_1 = {
            decryptedObject,
            feedbackArray,
            nextScreen,
            creditorDetails,
            fileUploadingObject
          }
          updateMetaData(obj_1)
        }
      })
      .catch(err => {
        fileUploadingObject.fileUploadingStop()
        console.log("aws error", err)
        notification.tryAgain()
      })
  })
  if (results.length === 0 && results.length === feedbackArray.length) {
    //  an array of results from aws
    //  it means files are uploaded  or not
    //  if yes then inserted an object otherwise returns false
    const obj_1 = {
      decryptedObject,
      feedbackArray,
      nextScreen,
      creditorDetails,
      fileUploadingObject
    }
    updateMetaData(obj_1)
  }
}
//  12th api
export const updateMetaData = obj => {
  const { decryptedObject, feedbackArray, creditorDetails, fileUploadingObject } = obj
  const { creditor, updateCreditorDetails } = creditorDetails
  //  u_status is true and creditor_id is present only then update the files in our database
  const updateUploadStatus = () => {
    creditor.u_status = true
    updateCreditorDetails(creditor)
  }
  if (feedbackArray.length === 0) {
    // console.log("update meta data files already uploaded")
    notification.filesAlreadyUploaded("files")
    //  also create the files to our database
    updateUploadStatus()
  } else {
    var metaDataStructure = {
      listAttribute4: feedbackArray,
      boolAttribute1: false,
      attribute15: 'false'
    }
    //   console.log("update meta data API structure", metaDataStructure)

    axios
      .post(urls.updateMetaData, { metaDataStructure: metaDataStructure }, {
        headers: {
          Authorization: "Bearer " + decryptedObject.token,
          'a': key
        },
      })
      .then(res => {
        const data = res.data.res
        console.log("meta data Response", data)
        //  201 code considered success
        if (data && data.messageCode === 201) {
          //    console.log(`${data.object.length} files sucessfully uploaded`, data)
          //  our file uploading work completed so we are going to stop this loading indicator

          fileUploadingObject.fileUploadingStop()
          notification.filesUploaded(`${data.object.length} files`)
          //  if everything is fine then save the file related information to our database
          updateUploadStatus()
        }
      })
      .catch(err => {
        console.error("update metadata API error", err)
        notification.filesNotUploaded("Files")
        fileUploadingObject.fileUploadingStop()
      })
  }
}
//  13th api
export const updateUploadingFiles = obj => {
  const { creditorDetails, nextScreen } = obj
  //  console.log("update uploading files", creditorDetails)
  const { creditor } = creditorDetails
  const { c_obj } = creditor
  const { files } = creditor.f_obj

  const fileDetailsArray = []
  //  destructure some fields from object

  const c_id = creditor.c_id
  const form_name = creditor.f_obj.form_name
  files.map(fileObj => {
    fileDetailsArray.push({
      creditorId: c_id,
      formName: form_name,
      fileName: fileObj.fileName,
      fileSize: fileObj.fileSize,
    })
  })
  const obj_1 = { fileDetailsArray, nextScreen }
  createFileDetails(obj_1)
}
//  14th api
export const createFileDetails = obj => {
  const { fileDetailsArray, nextScreen } = obj
  //console.log("fileDetails obj", fileDetailsArray)
  axios
    .post(urls.fileDetails, fileDetailsArray)
    .then(res => {
      //    console.log("create FileDetails Api response", res)
      nextScreen()
    })
    .catch(err => console.log("err", err))
}


export const getTotalClaims = (obj, setTotalClaims) => {
  const { folderId: rootFolderId, userId: rp_id } = obj
  //console.log("rp username and folder id", obj)
  const body = { rootFolderId, rp_id }
  axios
    .post(urls.totalClaims, body)
    .then(res => {
      //   console.log("get total claims Api response", res)
      setTotalClaims(res.data)
    })
    .catch(err => console.log("err", err))
}
export const captchaGeneration = (setCaptchaImage, fileUploadingObject) => {
  fileUploadingObject.fileUploadingStart()
  axios.get(urls.captchaGeneration).then(res => {
    fileUploadingObject.fileUploadingStop()

    const image = res.data
    setCaptchaImage(image)
  }).catch(err => {
    fileUploadingObject.fileUploadingStop()
    console.log("error ", err)
    notification.tryAgain()
  })
}

export const captchaVerification = (obj) => {
  const { captcha, phone_number, openModal, focusCaptchaField, nextScreen, creditorDetails, fileUploadingObject } = obj
  const { creditor, updateCreditorDetails } = creditorDetails

  const gmail_id = creditor.c_obj.email_id
  creditor.c_obj.phone_number = phone_number
  const body = { captcha: captcha, email_id: gmail_id }

  fileUploadingObject.fileUploadingStart()
  axios.post(urls.captchaVerfication, body).then(res => {
    fileUploadingObject.fileUploadingStop()
    const result = res.data.response
    // console.log("result", result)
    if (result) {
      nextScreen()
      /*//  console.log("creditor screen captch verification gmail_id : " + gmail_id);
      if (localStorage.getItem("resoluteemail") == gmail_id) {
        nextScreen()
      } else {
        //  we have to remove the captcha field as our captcha verification done
        const { creditor, updateCreditorDetails } = creditorDetails
        delete creditor.c_obj.captcha
        updateCreditorDetails(creditor)

        //captcha matches trigger the otpGeneration api for given user
        //check if time of generatuno isd 3 minutes then generate new otp
        var genotp = false;
        var curdate = new Date().getTime();
        if (localStorage.getItem("resoluteotp")) {
          var dt = parseFloat(localStorage.getItem("resoluteotp"));
          if ((curdate - dt) > (5 * 60000)) {
            genotp = true;
          }
        } else {
          genotp = true;
        }
        if (genotp) {
          otpGeneration(gmail_id, phone_number, captcha, fileUploadingObject)
        }
        setTimeout(() =>
          openModal(), 1000)
      }*/
    } else {
      //  remove field value and get focus on captcha field
      focusCaptchaField()
      notification.captchaNotMatched()
    }
  }).catch(err => {
    fileUploadingObject.fileUploadingStop()
    console.log("error ", err)
    notification.tryAgain()
  })
}

export const captchaClaimVerification = (obj) => {
  const { identification_no, claim_no, claim_email_no, phone_no, captcha_claim, 
    focusCaptchaField, nextScreen, creditorDetails, fileUploadingObject } = obj
  const { creditor, updateCreditorDetails } = creditorDetails
  const body = { captcha: captcha_claim }

  fileUploadingObject.fileUploadingStart()
  axios.post(urls.captchaVerfication, body).then(res => {
    const result = res.data.response
    if (result) {
      var regno = claim_no+"";
      regno = regno.replace("PCL_CA_", "");

      var idfno = identification_no;
      var email = claim_email_no;
      var phno = phone_no;

      if(idfno==null || idfno==undefined) idfno = "";
      if(email==null || email==undefined) email = "";
      if(phno==null || phno==undefined) phno = "";

      var query = `_id=${regno}`
      query += `&registration_number=${idfno}`
      query += `&email_id=${email}`
      query += `&phone_number=${phno}`
      console.log("getCreditorDetailsByClaimIdIdProof urls.getCreditorDetailsByClaimIdIdProof query: ", query)
      axios
        .get(urls.getCreditorDetailsByClaimIdIdProof + "?" + query)
        .then(res => {
          fileUploadingObject.fileUploadingStop()
          if(res.data != "") {
            console.log("getCreditorDetailsByClaimIdIdProof res.data : ", res.data)
            nextScreen()
          } else {
            notification.claimNotFound();
          }
      })
      .catch(err => {
        fileUploadingObject.fileUploadingStop()
        console.log("err", err)
        notification.claimNotFound()
      })
    } else {
      fileUploadingObject.fileUploadingStop()
      focusCaptchaField()
      notification.captchaNotMatched()
    }
  }).catch(err => {
    fileUploadingObject.fileUploadingStop()
    console.log("error ", err)
    notification.tryAgain()
  })
}

export const otpGeneration = (gmailId, phone_number, captcha, fileUploadingObject) => {
  const body = { gmail_id: gmailId, to: phone_number, captcha }
  var mobiledatabody = { to: phone_number };
  //axios.get(smssendurl).then(res => {
  //axios.post(urls.sendotpmobile, mobiledatabody).then(res => {
  fileUploadingObject.fileUploadingStart()
  axios.post(urls.otpGeneration, body).then(res => {
    fileUploadingObject.fileUploadingStop()
    //  console.log("otpGeneration response ", res)
    const result = res.data.response
    if (result) {
      notification.otpGeneration()
    }
  }).catch(err => {
    console.log("error ", err)
    fileUploadingObject.fileUploadingStop()
    notification.tryAgain()
  })
}
export const otpVerificationAPI = (obj) => {
  const { otp, email_id, nextScreen, fileUploadingObject } = obj
  const body = { otp: otp, email_id: email_id }
  console.log("otpVerfication body------------------------>", obj)
  fileUploadingObject.fileUploadingStart()
  axios.post(urls.otpVerfication, body).then(res => {
    fileUploadingObject.fileUploadingStop()
    const result = res.data.response
    //  console.log("OTP verfication api response", result)
    if (result) {
      notification.otpVerificationSuccess()
      nextScreen()
    } else {
      notification.otpVerficationFailed()
    }
  }).catch(err => {
    console.log("error ", err)
    fileUploadingObject.fileUploadingStop()
    notification.tryAgain()
  })
}

export const sendEmailToClaimant = (obj) => {
  const { creditorDetails } = obj
  const { creditor } = creditorDetails
  const claimant_email = creditor.c_obj.email_id
  const rp_name = creditor.c_obj.resolution_professional
  const claimno = creditorDetails.creditor.c_obj.claim_no_abcd

  var files = []
  creditor.f_obj.files.map(obj => {
    files.push(obj.eachFile)
  })

  var form_data = new FormData()

  form_data.append("email", claimant_email)
  form_data.append("rp_name", rp_name)
  form_data.append("claimno", claimno)

  files.map(obj => {
    form_data.append("name", obj.name)
    form_data.append("file", obj)
  })

  axios.post(urls.sendEmailToClaimant, form_data).then(res => {
    // console.log("send emailToClaimant api response", res.data)
    //notification.emailSendClaimantSuccess()
  }).catch(err => {
    //notification.emailSendClaimantFailed()
    console.log("err", err)
  })
}
export const sendEmailToRP = (obj) => {
  const { creditorDetails } = obj
  const { creditor } = creditorDetails
  const rp_email = creditor.c_obj.rp_email
  const claimant_name = creditor.c_obj.creditor
  var files = []
  creditor.f_obj.files.map(obj => {
    files.push(obj.fileName)
  })

  const body = { email: rp_email, files: files, claimant_name }

  axios.post(urls.sendEmailToRP, body).then(res => {
    //  console.log("send emailToRP api response", res.data)
    //notification.emailSendRPSuccess()
  }).catch(err => {
    //notification.emailSendRPFailed()
    console.log("err", err)
  })
}
export const exportLogs = () => {
  const body = { rp_id: auth.getRPId(), rootFolderId: auth.getRootFolderId() }
  if (body.rp_id === null) {
    notification.rootFolderNotSelected()
  } else {

    axios.post(urls.exportlogs, body).then(res => {
      //    console.log("export api response", res.data)
      const data = res.data.response
      const fileName = `creditor_details`

      exportToCSV(data, fileName)
    }).catch(err => {
      console.log("err", err)
    })
  }
}
export const exportToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);

  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);

}

export const getSessionToken = (redirectToDrive) => {
  const body = { "attribute1": "action" }
  axios.post(urls.generateSessionToken, body, {
    headers: {
      Authorization: "Bearer " + auth.getToken(),
    }
  }
  ).then(res => {
    // console.log("generate session token api response", res)
    var token = ""
    token = res?.data?.object?.attribute1
    if (token !== "") {
      redirectToDrive(token)
    }
  }).catch(err => {
    console.log("generate session token api error", err)
  })
}

//fetch creditor detauls by credeitor_claiom(form_name) and email id
export const getCreditorDetailsByEmailId = body => {
  //const { email_id, form_name, registration_number, setSinglecreditorobj } = body
  const { email_id, form_name, setSinglecreditorobj, fileUploadingObject } = body
  var query = `creditor_claim=${form_name}`
  query += `&email_id=${email_id}`
  //query += `&registration_number=${registration_number}`
  // console.log("get CreditorDetails urls.getCreditorDetailsByEmailForm : ", urls.getCreditorDetailsByEmailForm)
  // console.log("get CreditorDetails urls.getCreditorDetailsByEmailForm query: ", query)
  fileUploadingObject.fileUploadingStart()
  axios
    .get(urls.getCreditorDetailsByEmailForm + "?" + query)
    .then(res => {
      fileUploadingObject.fileUploadingStop()

      //  console.log("get CreditorDetails Api response By Email id and fomr name", res)
      const singlecreditor = res.data
      setSinglecreditorobj(singlecreditor)
      //  console.log("calling fetchcredetorresults", res)
    })
    .catch(err => {
      console.log("err", err)
      fileUploadingObject.fileUploadingStop()
      notification.tryAgain()
    })
}

//save as draft
export const createCreditorSaveAsDraft = (body) => {
  //const {  } = body

  //  console.log("createCreditorSaveAsDraft updateCreditorDetails body ", body)
  axios
    .post(urls.updateCreditorDetails, body)
    .then(res => {
      //    console.log("createCreditorSaveAsDraft updateCreditorDetails Api response", res.data)
      if(body.form_uploaded == "no" || body.edit_done=="draft") {
        notification.savedAsDraftSuccess()
      }
    })
    .catch(err => console.log("createCreditorSaveAsDraft updateCreditorDetails Api err", err))
}

export const holdsesinfo = (body) => {
  // console.log("holdinfo body ", body)
  axios
    .post(urls.holdinfo, body)
    .then(res => {
      //   console.log("holdinfo Api response", res.data)

    })
    .catch(err => console.log("holdinfo Api err", err))
}

export const getsesinfo = (body) => {
  const { setSesinfoNew } = body
  // console.log("getinfo body ", body)
  axios
    .post(urls.getinfo, body)
    .then(res => {
      //    console.log("getinfo Api response", res.data)
      setSesinfoNew(res.data);
    })
    .catch(err => console.log("getinfo Api err", err))
}

export const clearsesdata = (body) => {
  // console.log("cleardata body ", body)
  axios
    .post(urls.cleardata, body)
    .then(res => {
      //    console.log("cleardata Api response", res.data)
    })
    .catch(err => console.log("cleardata Api err", err))
}

//fetch creditor detauls by credeitor_claiom(form_name) and email id
export const getCreditorDetailsByClaimIdIdProof = body => {
  const { _id, registration_number, email_id, phone_number, setSinglecreditorobj, fileUploadingObject } = body
  var query = `_id=${_id}`
  query += `&registration_number=${registration_number}`
  query += `&email_id=${email_id}`
  query += `&phone_number=${phone_number}`
  fileUploadingObject.fileUploadingStart()
  console.log("getCreditorDetailsByClaimIdIdProof urls.getCreditorDetailsByClaimIdIdProof query: ", query)
  axios
    .get(urls.getCreditorDetailsByClaimIdIdProof + "?" + query)
    .then(res => {
      fileUploadingObject.fileUploadingStop()
      const singlecreditor = res.data
      setSinglecreditorobj(singlecreditor)
    })
    .catch(err => {
      fileUploadingObject.fileUploadingStop()
      console.log("err", err)
      notification.tryAgain()
    })
}

export const createLoginLog = (creditorDetails) => {
  var claimno = creditorDetails.creditor.c_obj.claim_no;
  var idproof = creditorDetails.creditor.c_obj.identification_no;
  const { creditor, updateCreditorDetails } = creditorDetails
  var obj = { "claim_id": claimno, "id_proof": idproof}

  /*const updateCreditorId = (creditor, creditorId) => {
    creditor.c_id = creditorId
    updateCreditorDetails(creditor)
  }*/
  //  we are removing this field because we dont require
  //  delete obj["creditor_claim"]
  console.log("createLoginLog-------------->", obj)
  axios
    //.post(urls.creditorDetails, obj)
    .post(urls.updateLoginLog, obj)
    .then(res => {
      //   console.log("create Creditor  update Api response", res)
      const creditorId = res.data._id
      //updateCreditorId(creditor, creditorId)
    })
    .catch(err => console.log("err", err))
}

