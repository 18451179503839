import React, { useState, useEffect } from "react";
import { Dropdown, Form, ListGroup, Row, Table } from "react-bootstrap";
import "./style.css";
import { Tooltip } from "react-tooltip";
import notification from "../../Utlitiy/notification";


function FormCA(props) {
  const [finCredtr, SetFinCredtr] = useState("");
  const [dropDownValue, SetDropDownValue] = useState("Select");
  const [dropDownIam, SetDropDownIm] = useState("Select");

  const { secondScreen, singlecreditorobj } = props
  //  console.log("fomrsca props--------->", props);


  //  this is the arrow function to handle any change happenend in any input
  //  its a generic function
  const mouseDown = (e) => {
    setTimeout(function(){
      if(e.target.id=="txt_from_name") notification.tooltipname();
      else if(e.target.id=="txt_from_address") notification.tooltipaddress();
      else if(e.target.id=="txt_doc_details") notification.tooltipdocdetails();
      else if(e.target.id=="txt_debt_incurred") notification.tooltipdebtoccured();
      else if(e.target.id=="txt_mutual_credit") notification.tooltipmutualcredit();
      else if(e.target.id=="txt_details_security_held") notification.tooltipdetailssecurityheld();
      else if(e.target.id=="txt_bank_account") notification.tooltipbankaccount();
      else if(e.target.id=="txt_doc_attach_list") notification.tooltipname();
      else if(e.target.id=="dropdown-11") notification.tooltipname();
      else if(e.target.id=="txt_name_in_block_letter") notification.tooltipsignaturepositionaddress();
      else if(e.target.id=="txt_creditor_position") notification.tooltipsignaturepositionaddress();
      else if(e.target.id=="txt_address_signing") notification.tooltipsignaturepositionaddress();
      else if(e.target.id=="dropdown-12") notification.tooltiprelatedparty();
    }, 200);
  };

  const elementBlur = (e) => {
    notification.hide();
  };

  useEffect(() => {
    var insolvency_professional = null
    insolvency_professional = singlecreditorobj?.insolvency_professional
    SetDropDownValue(insolvency_professional)

    if(singlecreditorobj?.from_name){
      document.getElementById("txt_from_name").value = singlecreditorobj?.from_name
      document.getElementById("lbl_creditor_name").innerHTML = singlecreditorobj?.from_name
      document.getElementById("lbl_from_name").innerHTML = singlecreditorobj?.from_name
      document.getElementById("lbl_claimant_name").innerHTML = singlecreditorobj?.from_name
      document.getElementById("lbl_cred_name").innerHTML = singlecreditorobj?.from_name
    }

    if(singlecreditorobj?.from_address){
      document.getElementById("txt_from_address").value = singlecreditorobj?.from_address
      document.getElementById("lbl_from_address").innerHTML = singlecreditorobj?.from_address
      document.getElementById("lbl_addremail").innerHTML = singlecreditorobj?.from_address + ", " + secondScreen.email_id
      document.getElementById("lbl_declartion_address").innerHTML = singlecreditorobj?.from_address + ", " + secondScreen.email_id
    }

    if(singlecreditorobj?.registration_number){
      document.getElementById("lbl_identification_doc").innerHTML = singlecreditorobj?.registration_number
    }

    if(singlecreditorobj?.from_address){
      document.getElementById("lbl_addremail").innerHTML = singlecreditorobj?.from_address
    }

    if(document.getElementById("txt_doc_details") && singlecreditorobj?.doc_reference){
      document.getElementById("txt_doc_details").value = singlecreditorobj?.doc_reference
      document.getElementById("lbl_doc_details").innerHTML = singlecreditorobj?.doc_reference
    }

    if(document.getElementById("txt_debt_incurred") && singlecreditorobj?.details_debt_occured){
      document.getElementById("txt_debt_incurred").value = singlecreditorobj?.details_debt_occured
      document.getElementById("lbl_debt_incurred").innerHTML = singlecreditorobj?.details_debt_occured
    }

    if(document.getElementById("txt_mutual_credit") && singlecreditorobj?.details_mutual_credit_debt){
      document.getElementById("txt_mutual_credit").value = singlecreditorobj?.details_mutual_credit_debt
      document.getElementById("lbl_mutual_credit").innerHTML = singlecreditorobj?.details_mutual_credit_debt
    }

    if(document.getElementById("txt_details_security_held") && singlecreditorobj?.details_security_held){
      document.getElementById("txt_details_security_held").value = singlecreditorobj?.details_security_held
      document.getElementById("lbl_details_security_held").innerHTML = singlecreditorobj?.details_security_held
    }

    if(document.getElementById("txt_bank_account") && singlecreditorobj?.details_bank_account){
      document.getElementById("txt_bank_account").value = singlecreditorobj?.details_bank_account
      document.getElementById("lbl_bank_account").innerHTML = singlecreditorobj?.details_bank_account
    }

    if(document.getElementById("txt_doc_attach_list") && singlecreditorobj?.details_debt_occured){
      document.getElementById("txt_doc_attach_list").value = singlecreditorobj?.documents_attached
      document.getElementById("lbl_doc_attach_list").innerHTML = singlecreditorobj?.documents_attached
    }

    if(singlecreditorobj?.insolvency_prof){
      document.getElementById("dropdown-11").value = singlecreditorobj?.insolvency_prof
      document.getElementById("lbl_dropdown_11").innerHTML = singlecreditorobj?.insolvency_prof
    }

    if(singlecreditorobj?.name_in_block_letter){
      document.getElementById("txt_name_in_block_letter").value = singlecreditorobj?.name_in_block_letter
      document.getElementById("lbl_name_in_block_letter").innerHTML = singlecreditorobj?.name_in_block_letter
    }
    
    if(singlecreditorobj?.position_with_creditor){
      document.getElementById("txt_creditor_position").value = singlecreditorobj?.position_with_creditor
      document.getElementById("lbl_creditor_position").innerHTML = singlecreditorobj?.position_with_creditor
    }

    if(singlecreditorobj?.address_person_signing){
      document.getElementById("txt_address_signing").value = singlecreditorobj?.address_person_signing
      document.getElementById("lbl_address_signing").innerHTML = singlecreditorobj?.address_person_signing
    }

    if(singlecreditorobj?.related_corporate_debtor){
      document.getElementById("dropdown-12").value = singlecreditorobj?.related_corporate_debtor
      document.getElementById("lbl_dropdown_12").innerHTML = singlecreditorobj?.related_corporate_debtor
    }

  }, [singlecreditorobj])

  useEffect(() => {
    var date = new Date();
    document.getElementById("lbl_form_date").innerHTML = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  }, []);

   //console.log("fomrscreen : ", secondScreen)


  function handleChange(event) {
   // console.log("handleChange : " + event.target.value + " :: " + event.target.id);
    var lblid = event.target.id.replace("txt_", "lbl_");
    document.getElementById(lblid).innerHTML = event.target.value;
   // console.log("1w1w1w1w1w :: " + document.getElementById(lblid).innerHTML);

    if (event.target.id == "txt_from_name") {
      event.target.value = (event.target.value).replace(/[^\w ]/g, '');
      if (event.target.value != "") {
        document.getElementById("lbl_cred_name").innerHTML = event.target.value;
        document.getElementById("lbl_claimant_name").innerHTML = event.target.value;
        document.getElementById("lbl_creditor_name").innerHTML = event.target.value;
        document.getElementById("lbl_ver_name").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_cred_name").innerHTML = "[Name of the financial creditor]";
        document.getElementById("lbl_claimant_name").innerHTML = "[Name of claimant]";
        document.getElementById("lbl_creditor_name").innerHTML = "";
        document.getElementById("lbl_ver_name").innerHTML = "[Name]";
      }
    }

    if (event.target.id == "txt_from_address") {
      if (event.target.value != "") {
        document.getElementById("lbl_addremail").innerHTML = event.target.value + ", " + secondScreen.email_id;
        document.getElementById("lbl_declartion_address").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_addremail").innerHTML = "";
        document.getElementById("lbl_declartion_address").innerHTML = "……………………………………………………… …………………………………………………";
      }
    }

    if (event.target.id == "txt_doc_details") {
      if (event.target.value != "") {
        document.getElementById("lbl_doc_list").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_doc_list").innerHTML = "[Please list the documents relied on as evidence of claim]";
      }
    }

    if (event.target.id == "txt_mutual_credit") {
      if (event.target.value != "") {
        document.getElementById("lbl_saidsum").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_saidsum").innerHTML = "[Please state details of any mutual credit, mutual debts, or other mutual dealings between the corporate debtor and the creditor which may be set-off against the claim]";
      }
    }
  }
  function handleChangeinsolvencypref(event){
    document.getElementById("lbl_dropdown_11").innerHTML = event.target.value;
    SetDropDownValue(event.target.value)
  }

  function handleChangeiam(event) {
    document.getElementById("lbl_dropdown_12").innerHTML = event.target.value;
    SetDropDownValue(event.target.value)
  }

  var amt = parseInt(secondScreen.amount_claimed)// + parseInt(secondScreen.creditor)

  return (
    <Row>
      <div className="container-fluid formCA" id="formCA">
        <div className="heading">
          <h6 className="subHeading headingFormat">FORM CA</h6>
          <h6 className="subHeading headingFormat">
            SUBMISSION OF CLAIM BY FINANCIAL CREDITORS IN A CLASS
          </h6>
          <p>
            (Under Regulation 8A of the Insolvency and Bankruptcy (Insolvency
            Resolution Process for Corporate Persons) Regulations, 2016)
          </p>
        </div>
        <div className="section1">
          <div className="from ">
            <p><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>From</p>
            <Form>
              <Form.Group>
                <Form.Control required type="text" id="txt_from_name" onChange={handleChange} 
                  className="txtallclasses" placeholder="Name" onMouseDown={mouseDown} onBlur={elementBlur}
                  maxLength="250" />
                <label id='lbl_from_name' className="lblallclasses displaynone"></label>
              </Form.Group>
            </Form>
          </div>

          <div className="from">
            <p>Date</p>
            <Form>
              <Form.Group>
                <label id="lbl_form_date"></label>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="section1">
          <div className="from ">
            <Form>
              <Form.Group>
              <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
              <textarea required cols="50" className="width100perc txtallclasses" id="txt_from_address" 
                onChange={handleChange} placeholder="Address" onMouseDown={mouseDown} onBlur={elementBlur} />
                <label id='lbl_from_address' className="lblallclasses displaynone"></label>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="to">
          <div className="from"> To</div>
          <div className="from">
            The Interim Resolution Professional / Resolution Professional
            <br />
            Rajesh Sureshchandra Sheth
            <br />
            <b>Registered Address</b>: B-55, Shatdal Society, 7th Floor, Azad Lane, Off S.V. Road, Andheri West, Near Shoppers Stop, Mumbai 400058, Maharashtra
            <br />
            <b>Correspondence Address</b>: Deloitte India Insolvency Professionals LLP, 27th Floor, Tower 3, One International Center, Senapati Bapat Marg, Elphinstone (W), Mumbai 400 013

          </div>
          <Form>
            <Form.Group>
              <label></label>
            </Form.Group>
          </Form>
        </div>
        <div className="subject">
          <p>Subject: Submission of claim and proof of claim.</p>
        </div>
        <div className="formBody">
          <p>Madam/Sir,</p>
          <div className="txtjustify">
            <label id="lbl_cred_name" >[Name of the financial creditor]</label>, hereby submits this claim in respect of
            the corporate insolvency resolution process of Pancard Clubs Limited.
            The details for the same are set out below:
          </div>
        </div>
        <Table className="table1" size="sm" bordered hover>
          <tbody>
            <tr className="headtr">
              <td colSpan={3}>RELEVANT PARTICULARS</td>
            </tr>
            <tr>
              <td>1. </td>
              <td>Name of the financial creditor</td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <label id='lbl_creditor_name' className="lblallclasses" ></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>2. </td>
              <td>
                Identification number of the financial creditor (If an
                incorporated body, provide identification number and proof of
                incorporation. If a partnership or individual, provide
                identification records of all the partners or the individual)
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <label id='lbl_identification_doc' className="lblallclasses"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>3. </td>
              <td>
                Address and e-mail address of the financial creditor for
                correspondence.
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <label id='lbl_addremail' className="lblallclasses"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>4. </td>
              <td>Total amount of claim (in Rs.)</td>
              <td className="test">
                <Form>
                  <Form.Group>
                    {amt > 0 ? amt : ""}
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>5. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Details of documents by reference to which the debt can be
                substantiated.
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" id="txt_doc_details" onChange={handleChange} 
                      onMouseDown={mouseDown} onBlur={elementBlur} />
                    <label id='lbl_doc_details' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>6. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Details of how and when debt incurred.
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_debt_incurred" 
                      onChange={handleChange} onMouseDown={mouseDown} onBlur={elementBlur} />
                    <label id='lbl_debt_incurred' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>7. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Details of any mutual credit, mutual debts, or other mutual dealings between the corporate debtor and the creditor which may be set-off against the claim.
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_mutual_credit" 
                      onChange={handleChange} onMouseDown={mouseDown} onBlur={elementBlur} />
                    <label id='lbl_mutual_credit' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>8. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Details of any security held, the value of the security, and the date it was given
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_details_security_held" 
                      onChange={handleChange} onMouseDown={mouseDown} onBlur={elementBlur} />
                    <label id='lbl_details_security_held' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>9. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Details of the bank account to which the amount of the claim or any part thereof can be transferred pursuant to a resolution plan
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_bank_account" 
                      onChange={handleChange} onMouseDown={mouseDown} onBlur={elementBlur} />
                    <label id='lbl_bank_account' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>10. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                List of documents attached to this claim in order to prove the existence and non-payment of claim due
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_doc_attach_list" 
                      onChange={handleChange} />
                    <label id='lbl_doc_attach_list' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>11. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Name of the insolvency professional who will act as the
                Authorised representative of creditors of the class
              </td>
              <td className="test">
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                <select aria-label="Default select example" className="custselectlist" id="dropdown-11" 
                  onChange={handleChangeinsolvencypref} >
                  <option value={"Select"}>Select</option>
                  <option value={"Mr. Devang Subodh Thakar"}>Mr. Devang Subodh Thakar</option>
                  <option value={"Mr. Vithal Madhukar Dahake"}>Mr. Vithal Madhukar Dahake</option>
                  <option value={"Mr. Prakash V Kukreja"}>Mr. Prakash V Kukreja</option>
                </select>
                <label id='lbl_dropdown_11' className="lblallclasses displaynone"></label>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table className="table2" size="sm" bordered hover>
          <tbody>
            <tr className="headtr">
              <td colSpan={3}>
                <p className="pt-2 margintop2px">
                  Signature of financial creditor or person authorised to act on
                  its behalf
                </p>
                <p className="">
                  [Please enclose the authority if this is being submitted on
                  behalf of the financial creditor]
                </p>
              </td>
            </tr>
            <tr className="headtr">
              <td colSpan={3}><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Name in BLOCK LETTERS : <Form.Control required type="text" id="txt_name_in_block_letter"
                onChange={handleChange} className="txtallclasses capitalletters" 
                onMouseDown={mouseDown} onBlur={elementBlur} />
                <label id='lbl_name_in_block_letter' className="lblallclasses displaynone"></label></td>
            </tr>
            <tr className="headtr">
              <td colSpan={3}><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Position with or in relation to creditor :
                <textarea className="width100perc txtallclasses" required rows="3" id="txt_creditor_position" 
                  onChange={handleChange} onMouseDown={mouseDown} onBlur={elementBlur} />
                <label id='lbl_creditor_position' className="lblallclasses displaynone"></label></td>
            </tr>
            <tr className="headtr">
              <td colSpan={3}><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Address of person signing :
                <textarea className="width100perc txtallclasses" required rows="3" id="txt_address_signing" 
                  onChange={handleChange} onMouseDown={mouseDown} onBlur={elementBlur} />
                <label id='lbl_address_signing' className="lblallclasses displaynone"></label></td>
            </tr>
          </tbody>
        </Table>
        *PAN number, passport, AADHAAR Card or the identity card issued by the Election Commission of India.
        <div className="">
          <div className="heading">
            <h6 className=" heading headingFormat">DECLARATION</h6>
          </div>
          <p>
            I, <label id="lbl_claimant_name">[Name of claimant]</label>, currently residing at
            &nbsp;<label id="lbl_declartion_address">……………………………………………………… …………………………………………………</label>, do
            hereby declare and state as follows: -{" "}
          </p>
          <ol>
            <li><p>
              Pancard Clubs Limited, the corporate debtor was, at the
              insolvency commencement date, being the 9th day of September 2022, actually indebted to me for a sum of Rs.
              {amt > 0 ? amt : "[insert amount of claim]"}
            </p></li>
            <li><p>
              In respect of my claim of the said sum or any part thereof, I
              have relied on the documents specified below:<br />
              <label id="lbl_doc_list">[Please list the documents relied on as evidence of claim]</label>.
            </p></li>
            <li><p>
              The said documents are true, valid and genuine to the best of my knowledge,
              information and belief and no material facts have been concealed therefrom.
            </p></li>
            <li><p>
              In respect of the said sum or any part thereof, neither I, nor any person,
              by my order, to my knowledge or belief, for my use, had or received any manner
              of satisfaction or security whatsoever, save and except the following:<br />
              <label id="lbl_saidsum">[Please state details of any mutual credit, mutual debts, or other mutual dealings between the corporate debtor and the creditor which may be set-off against the claim]</label>
            </p></li>
            <li> <p style={{
              display: 'flex',
              alignItems: 'baseline'
            }}>
              <select aria-label="Default select example" className="custselectlist" id="dropdown-12" 
                  onChange={handleChangeiam} onMouseDown={mouseDown} onBlur={elementBlur} >
                  <option value={"Select"}>Select</option>
                  <option value={"I am"}>I am</option>
                  <option value={"I am not"}>I am not</option>
                </select>
                <span id='lbl_dropdown_12' className="lblallclasses displaynone"></span>
                &nbsp;a related party of the corporate debtor, as defined under section 5 (24) of the Code.
            </p></li>
            <li><p>
              I am eligible to give voting instruction to the authorized representative by virtue of proviso to
              section 21 (2) of the Code even though I am a related party of the corporate debtor.
            </p></li>
          </ol>
          <div>
            <div className="d-flex justify-content-between"><p>Date :</p> </div>
            <div className="d-flex justify-content-between"><p>Place :</p> <p>(Signature of the claimant)</p></div>
            <p className="text-center font-weight-bolder">VERIFICATION</p>
            <p>I, <label id="lbl_ver_name">[Name]</label> the claimant hereinabove, do hereby verify that the contents of this proof of claim are true and correct to my knowledge and belief and no material fact has been concealed therefrom.
            </p>
            <p>Verified at ………………………… on this ………………… day of …………, 20…………
            </p>
            <p className="text-right">(Signature of claimant)
            </p>
            <p>[Note: In the case of company or limited liability partnership, the declaration and verification shall be made by the director/manager/secretary/designated partner and in the case of other entities, an officer authorized for the purpose by the entity.]
            </p>
          </div>
        </div>
      </div>
    </Row>
  );
}

export default FormCA;
